interface TEnvironment {
  environment: string;
  appVersion: string;
  butterCMSToken: string;
  commitHash: string;
  filestackApiKey: string;
  fontAwesomeApiUrl: string;
  geolocationApi: string;
  googleMapsApiKey: string;
  gridApi: string;
  insightsKey: string;
  myGrimmeCoreApi: string;
  newsletterSubscriptionApiUrl: string;
  onlineSalesApi: string;
  onlineSalesUrl: string;
  production: boolean;
  profileApi: string;
  projectName: string;
  registrationLink: string;
  siteUrl: string;
  syncApiUrl: string;
  azureAppConfigEndpoint: string;
  globalSearchApiEndpoint: string;
}

export const environment: TEnvironment = {
  environment: process.env.NEXT_PUBLIC_ENVIRONMENT || 'production',
  appVersion: process.env.NEXT_PUBLIC_NPM_PACKAGE_VERSION || '0.0.0',
  butterCMSToken: process.env.BUTTERCMS_API_TOKEN || '',
  commitHash: process.env.NEXT_PUBLIC_COMMIT_HASH ?? '0000000',
  filestackApiKey: process.env.NEXT_PUBLIC_FILESTACK_API_KEY || '',
  fontAwesomeApiUrl: process.env.NEXT_PUBLIC_FONT_AWESOME_API || '',
  geolocationApi:
    process.env.NEXT_PUBLIC_GEOLOCATION_URI ||
    'https://my-grimme-core-tst.azurewebsites.net/api/geolocation',
  googleMapsApiKey: process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY || '',
  gridApi:
    process.env.NEXT_PUBLIC_GRID_API || 'https://grid-tst.azurewebsites.net',
  insightsKey: process.env.NEXT_PUBLIC_APP_INSIGHTS_KEY || '',
  myGrimmeCoreApi:
    process.env.NEXT_PUBLIC_MY_GRIMME_CORE_API || 'https://localhost:3334/api',
  newsletterSubscriptionApiUrl:
    process.env.NEXT_PUBLIC_NEWSLETTER_SUBSCRIPTION_API_URL ||
    'https://dcj-email-service-tst.azurewebsites.net/api/v1/newsletter/subscription',
  onlineSalesApi:
    process.env.NEXT_PUBLIC_ONLINE_SALES_API ||
    'https://online-sales-tst.azurewebsites.net/api',
  onlineSalesUrl:
    process.env.NEXT_PUBLIC_ONLINE_SALES_URL ||
    'https://tst.products.grimme.com',
  production: process.env.NODE_ENV === 'production',
  profileApi:
    process.env.NEXT_PUBLIC_PROFILE_API || 'https://localhost:3334/api',
  projectName: process.env.NEXT_PUBLIC_NPM_PACKAGE_NAME || 'grimme-website',
  registrationLink: process.env.NEXT_PUBLIC_REGISTRATION_LINK || '',
  siteUrl: process.env.NEXT_PUBLIC_SITE_URL || 'https://localhost:3000',
  syncApiUrl:
    process.env.NEXT_PUBLIC_SYNC_API ||
    'https://my-grimme-translations-tst.azurewebsites.net/api',
  azureAppConfigEndpoint:
    process.env.NEXT_PUBLIC_AZURE_APP_CONFIG_ENDPOINT || '',
  globalSearchApiEndpoint:
    process.env.NEXT_PUBLIC_GLOBAL_SEARCH_API_ENDPOINT ||
    'https://global-search-api-tst.azurewebsites.net',
};
